export default (valueProperty, searchProperty) => ({
    open: false,
    inputValue: '',
    selectedName: '',
    highlightedIndex: -1,
    init() {
        this.inputValue = this.$wire.get(searchProperty);
        this.selectedName = this.$wire.get(searchProperty);

        this.selectRecord(this.$wire.get(valueProperty));
    },
    selectRecord(id) {
        const records = this.$refs.records.querySelectorAll('button');

        records.forEach((record, index) => {
            if (record.getAttribute('data-id') === id) {
                this.highlightedIndex = index;
                this.selectedName = record.getAttribute('data-name');
                this.inputValue = this.selectedName;
            }
        });

        this.$wire.set(valueProperty, id);
        this.open = false;
    },
    handleClosing() {
        if (this.inputValue !== this.selectedName) {
            this.inputValue = this.selectedName;
        }

        this.open = false;
    },
    highlightNextRecord() {
        this.open = true;

        const records = this.$refs.records.querySelectorAll('button');
        if (this.highlightedIndex < records.length - 1) {
            this.highlightedIndex++;
            this.updateHighlight(records);
        }
    },
    highlightPreviousRecord() {
        this.open = true;
        const records = this.$refs.records.querySelectorAll('button');
        if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
            this.updateHighlight(records);
        }
    },
    updateHighlight(records) {
        records.forEach((record, index) => {
            if (index === this.highlightedIndex) {
                record.classList.add('bg-wpp-gray-100');
            } else {
                record.classList.remove('bg-wpp-gray-100');
            }
        });
    },
    selectHighlightedRecord() {
        const records = this.$refs.records.querySelectorAll('button');
        if (this.highlightedIndex >= 0 && this.highlightedIndex < records.length) {
            const record = records[this.highlightedIndex];
            this.selectRecord(record.getAttribute('data-id'));
        }
    },
});
